<template>
    <section class="section">
        <div class="container">
            <div class="content is-medium">
                <h2>Datenschutzerkl&auml;rung</h2>
                <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
                    EU-Datenschutzgrundverordnung (DSGVO), ist:</p><br />
                <p>Swiss Domino Effect GmbH<br />Daniel Huwiler & Jonas Oswald<br />Bucherstrasse 2<br />9322 Egnach</p>
                <p>Telefon: 079 837 51 73<br />E-Mail: info@swissdominoeffect.com<br />WebSite:
                    https://swissdominoeffect.com/ </p>
                <p>&nbsp;</p>
                <h2>Allgemeiner Hinweis</h2>
                <p>Gest&uuml;tzt auf Artikel 13 der schweizerischen Bundesverfassung und den datenschutzrechtlichen
                    Bestimmungen des Bundes (Datenschutzgesetz, DSG
                    <!--DSG-->) hat jede Person Anspruch auf Schutz ihrer Privatsph&auml;re sowie auf Schutz vor
                    Missbrauch ihrer pers&ouml;nlichen Daten. Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                    pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
                    entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.</p>
                <p>In Zusammenarbeit mit unseren Hosting-Providern bem&uuml;hen wir uns, die Datenbanken so gut wie
                    m&ouml;glich vor fremden Zugriffen, Verlusten, Missbrauch oder vor F&auml;lschung zu sch&uuml;tzen.
                </p>
                <p>Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation per
                    E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff
                    durch Dritte ist nicht m&ouml;glich.</p>
                <p>Durch die Nutzung dieser Website erkl&auml;ren Sie sich mit der Erhebung, Verarbeitung und Nutzung
                    von Daten gem&auml;ss der nachfolgenden Beschreibung einverstanden. Diese Website kann
                    grunds&auml;tzlich ohne Registrierung besucht werden. Dabei werden Daten wie beispielsweise
                    aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken auf
                    dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden.
                    Personenbezogene Daten, insbesondere Name, Adresse oder E-Mail-Adresse werden soweit m&ouml;glich
                    auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an Dritte.
                </p>
                <p>&nbsp;</p>
                <h2 id="cookies">Datenschutzerkl&auml;rung f&uuml;r Cookies</h2>
                <p>Diese Website verwendet Cookies. Das sind kleine Textdateien, die es m&ouml;glich machen, auf dem
                    Endger&auml;t des Nutzers spezifische, auf den Nutzer bezogene Informationen zu speichern,
                    w&auml;hrend er die Website nutzt. Cookies erm&ouml;glichen es, insbesondere Nutzungsh&auml;ufigkeit
                    und Nutzeranzahl der Seiten zu ermitteln, Verhaltensweisen der Seitennutzung zu analysieren, aber
                    auch unser Angebot kundenfreundlicher zu gestalten.
                    <!--Allg.Cookies-->Cookies bleiben &uuml;ber das Ende einer Browser-Sitzung gespeichert und
                    k&ouml;nnen bei einem erneuten Seitenbesuch wieder aufgerufen werden. Wenn Sie das nicht
                    w&uuml;nschen, sollten Sie Ihren Internetbrowser so einstellen, dass er die Annahme von Cookies
                    verweigert.</p>
                <p>Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies
                    kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische
                    Seite <a href="http://www.aboutads.info/choices/" target="_blank"
                        rel="noopener">http://www.aboutads.info/choices/</a> oder die EU-Seite <a
                        href="http://www.youronlinechoices.com/" target="_blank"
                        rel="noopener">http://www.youronlinechoices.com/</a> erklärt werden. Des Weiteren kann die
                    Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden.
                    Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt
                    werden können.</p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r SSL-/TLS-Verschl&uuml;sselung</h2>
                <p>Diese Website nutzt aus Gr&uuml;nden der Sicherheit und zum Schutz der &Uuml;bertragung vertraulicher
                    Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine
                    SSL-/TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die
                    Adresszeile des Browsers von &quot;http://&quot; auf &quot;https://&quot; wechselt und an dem
                    Schloss-Symbol in Ihrer Browserzeile.</p>
                <p>Wenn die SSL bzw. TLS Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns
                    &uuml;bermitteln, nicht von Dritten mitgelesen werden.</p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Kontaktformular</h2>
                <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                    Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage
                    und f&uuml;r den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
                    Einwilligung weiter.</p>
                <p>&nbsp;</p>
                <h2>Kostenpflichtige Leistungen</h2>
                <p>Zur Erbringung kostenpflichtiger Leistungen werden von uns zus&auml;tzliche Daten erfragt, wie z.B.
                    Zahlungsangaben, um Ihre Bestellung resp. Ihren Auftrag ausf&uuml;hren zu k&ouml;nnen. Wir speichern
                    diese Daten in unseren Systemen, bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind.</p>
                <p>&nbsp;</p>
                <h2>Verwendung von Google reCAPTCHA</h2>
                <p>Diese Website verwendet den Dienst reCAPTCHA der Google Ireland Limited (Gordon House, Barrow Street
                    Dublin 4, Irland „Google“). Die Abfrage dient dem Zweck der Unterscheidung, ob die Eingabe durch
                    einen Menschen oder durch automatisierte, maschinelle Verarbeitung erfolgt. Die Abfrage schliesst
                    den Versand der IP-Adresse und ggf. weiterer von Google f&uuml;r den Dienst reCAPTCHA
                    ben&ouml;tigter Daten an Google ein. Zu diesem Zweck wird Ihre Eingabe an Google &uuml;bermittelt
                    und dort weiter verwendet. Ihre IP-Adresse wird von Google jedoch innerhalb von Mitgliedstaaten der
                    Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens &uuml;ber den
                    Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle
                    IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Im Auftrag
                    des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung dieses
                    Dienstes auszuwerten. Die im Rahmen von reCaptcha von Ihrem Browser &uuml;bermittelte IP-Adresse
                    wird nicht mit anderen Daten von Google zusammengef&uuml;hrt. Ihre Daten werden dabei gegebenenfalls
                    auch in die USA &uuml;bermittelt. F&uuml;r Daten&uuml;bermittlungen in die USA ist ein
                    Angemessenheitsbeschluss der Europ&auml;ischen Kommission, das "Privacy Shield", vorhanden. Google
                    nimmt am "Privacy Shield" teil und hat sich den Vorgaben unterworfen. Mit Bet&auml;tigen der Abfrage
                    willigen Sie in die Verarbeitung Ihrer Daten ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6
                    (1) lit. a DSGVO mit Ihrer Einwilligung. Sie k&ouml;nnen Ihre Einwilligung jederzeit widerrufen,
                    ohne dass die Rechtm&auml;ssigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
                    Verarbeitung ber&uuml;hrt wird.</p>
                <p>N&auml;here Informationen zu Google reCAPTCHA sowie die dazugeh&ouml;rige Datenschutzerkl&auml;rung
                    finden Sie unter: <a href="https://policies.google.com/privacy?hl=de" target="_blank"
                        rel="noopener">https://policies.google.com/privacy?hl=de</a></p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Google Analytics</h2>
                <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited. Wenn der
                    Verantwortliche für die Datenverarbeitung auf dieser Website ausserhalb des Europäischen
                    Wirtschaftsraumes oder der Schweiz sitzt, dann erfolgt die Google Analytics Datenverarbeitung durch
                    Google LLC. Google LLC und Google Ireland Limited werden nachfolgend &laquo;Google&raquo; genannt.
                </p>
                <p>Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer
                    interessanter ausgestalten. Diese Website verwendet Google Analytics zudem für eine
                    geräteübergreifende Analyse von Besucherströmen, die über eine User-ID durchgeführt wird. Sofern Sie
                    über ein Google-Benutzerkonto verfügen, können Sie in den dortigen Einstellungen unter &laquo;Meine
                    Daten&raquo;, &laquo;persönliche Daten&raquo; die geräteübergreifende Analyse Ihrer Nutzung
                    deaktivieren.</p>
                <p>Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Die im
                    Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten
                    von Google zusammengeführt. Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um
                    den Code &laquo;_anonymizeIp();&raquo; erweitert wurde, um eine anonymisierte Erfassung von
                    IP-Adressen zu gewährleisten. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine
                    Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein
                    Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit
                    umgehend gelöscht.</p>
                <p>Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und
                    dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um
                    Ihre Nutzung der Website auszuwerten, um Reports über die Websitenaktivitäten zusammenzustellen und
                    um weitere mit der Websitennutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
                    Websitenbetreiber zu erbringen. Für die Ausnahmefälle, in denen personenbezogene Daten in die USA
                    übertragen werden, hat sich Google dem EU-US Privacy Shield unterworfen, <a
                        href="https://www.privacyshield.gov/EU-US-Framework" target="_blank"
                        rel="noopener">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
                <p>Google Analytics verwendet Cookies. Die durch den Cookie erzeugten Informationen über Ihre Benutzung
                    dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort
                    gespeichert. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
                    Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                    gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie
                    können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website
                    bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
                    verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
                    installieren: <a href="https://www.swissanwalt.ch/de/gaoptout.aspx" target="_blank"
                        rel="noopener">Google Analytics deaktivieren</a>.</p>
                <p>Ausserdem können Sie die Nutzung von Google Analytics auch verhindern, indem sie auf diesen Link
                    klicken: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
                        rel="noopener">Google Analytics deaktivieren</a>. Hierdurch wird ein sog. opt-out Cookie auf
                    ihrem Datenträger gespeichert, der die Verarbeitung personenbezogener Daten durch Google Analytics
                    verhindert. Bitte beachten Sie, dass bei einem Löschen sämtlicher Cookies auf Ihrem Endgerät auch
                    diese Opt-out-Cookies gelöscht werden, d.h., dass Sie erneut die Opt-out-Cookies setzen müssen, wenn
                    Sie weiterhin diese Form der Datenerhebung verhindern wollen. Die Opt-out-Cookies sind pro Browser
                    und Rechner/Endgerät gesetzt und müssen daher für jeden Browser, Rechner oder anderes Endgerät
                    gesondert aktiviert werden.</p>
                <p>&nbsp;</p>
                <h2>Google Tag Manager</h2>
                <p>Google Tag Manager ist eine L&ouml;sung, mit der wir sog. Website-Tags &uuml;ber eine Oberfl&auml;che
                    verwalten k&ouml;nnen und so z.B. Google Analytics sowie andere Google-Marketing-Dienste in unser
                    Onlineangebot einbinden können. Der Tag Manager selbst, welcher die Tags implementiert, verarbeitet
                    keine personenbezogenen Daten der Nutzer. Im Hinblick auf die Verarbeitung der personenbezogenen
                    Daten der Nutzer wird auf die folgenden Angaben zu den Google-Diensten verwiesen.
                    Nutzungsrichtlinien: <a href="https://www.google.com/intl/de/tagmanager/use-policy.html"
                        target="_blank" rel="noopener">https://www.google.com/intl/de/tagmanager/use-policy.html</a>.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Facebook</h2>
                <p>Diese Website verwendet Funktionen von Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304,
                    USA . Bei Aufruf unserer Seiten mit Facebook-Plug-Ins wird eine Verbindung zwischen Ihrem Browser
                    und den Servern von Facebook aufgebaut. Dabei werden bereits Daten an Facebook &uuml;bertragen.
                    Besitzen Sie einen Facebook-Account, k&ouml;nnen diese Daten damit verkn&uuml;pft werden. Wenn Sie
                    keine Zuordnung dieser Daten zu Ihrem Facebook-Account w&uuml;nschen, loggen Sie sich bitte vor dem
                    Besuch unserer Seite bei Facebook aus. Interaktionen, insbesondere das Nutzen einer
                    Kommentarfunktion oder das Anklicken eines „Like“- oder „Teilen“-Buttons werden ebenfalls an
                    Facebook weitergegeben. Mehr erfahren Sie unter <a href="https://de-de.facebook.com/about/privacy"
                        target="_blank" style="color: inherit;">https://de-de.facebook.com/about/privacy</a>.</p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Twitter</h2>
                <p>Diese Website verwendet Funktionen von Twitter, Inc., 1355 Market St, Suite 900, San Francisco, CA
                    94103, USA. Bei Aufruf unserer Seiten mit Twitter-Plug-Ins wird eine Verbindung zwischen Ihrem
                    Browser und den Servern von Twitter aufgebaut. Dabei werden bereits Daten an Twitter
                    &uuml;bertragen. Besitzen Sie einen Twitter-Account, k&ouml;nnen diese Daten damit verkn&uuml;pft
                    werden. Wenn Sie keine Zuordnung dieser Daten zu Ihrem Twitter-Account wünschen, loggen Sie sich
                    bitte vor dem Besuch unserer Seite bei Twitter aus. Interaktionen, insbesondere das Anklicken eines
                    „Re-Tweet“-Buttons werden ebenfalls an Twitter weitergegeben. Mehr erfahren Sie unter <a
                        href="https://twitter.com/privacy" target="_blank"
                        style="color: inherit;">https://twitter.com/privacy</a>.</p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Instagram</h2>
                <p>Auf unserer Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden
                    angeboten durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA integriert. Wenn
                    Sie in Ihrem Instagram-Account eingeloggt sind k&ouml;nnen Sie durch Anklicken des Instagram-Buttons
                    die Inhalte unserer Seiten mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch
                    unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten
                    keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie deren Nutzung durch Instagram erhalten.
                </p>
                <p>Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von Instagram: <a
                        href="https://instagram.com/about/legal/privacy/" target="_blank"
                        style="color: inherit;">http://instagram.com/about/legal/privacy/</a></p>
                <p>&nbsp;</p>
                <h2>Audio- und Video-Konferenzen</h2>
                <p>Wir verwenden Dienste f&uuml;r Audio- und Video-Konferenzen, um mit unseren Nutzern sowie sonstigen
                    Personen kommunizieren zu k&ouml;nnen. Wir k&ouml;nnen damit insbesondere Audio- und
                    Video-Konferenzen, virtuelle Besprechungen sowie Schulungen wie beispielsweise Webinare
                    durchf&uuml;hren.</p>
                <p>Wir verwenden nur Dienste, bei denen ein angemessener Datenschutz gew&auml;hrleistet ist. Es gelten
                    erg&auml;nzend zu dieser Datenschutzerkl&auml;rung jeweils auch allf&auml;llige Bedingungen der
                    verwendeten Dienste wie beispielsweise Nutzungsbedingungen oder Datenschutzerkl&auml;rungen.</p>
                <p>Wir verwenden insbesondere <strong>Zoom</strong>, einen Dienst der amerikanischen Zoom Video
                    Communications Inc. Die Rechte gem&auml;ss der europ&auml;ischen Datenschutz-Grundverordnung (DSGVO)
                    gew&auml;hrt Zoom auch gegen&uuml;ber Nutzerinnen und Nutzern in der Schweiz. Weitere Angaben
                    &uuml;ber Art, Umfang und Zweck der Datenbearbeitung finden sich in den <a
                        href="https://zoom.us/de-de/privacy.html" target="_blank"
                        rel="noopener noreferrer">Datenschutzrichtlinien</a> sowie auf der <a
                        href="https://zoom.us/docs/de-de/privacy-and-legal.html" target="_blank"
                        rel="noopener noreferrer">Seite &laquo;Rechtliche Bestimmungen und Datenschutz&raquo;</a>
                    jeweils von Zoom.</p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r YouTube</h2>
                <p>Auf dieser Website sind Funktionen des Dienstes &laquo;YouTube&raquo; eingebunden.
                    &laquo;YouTube&raquo; geh&ouml;rt der Google Ireland Limited, einer nach irischem Recht
                    eingetragenen und betriebenen Gesellschaft mit Sitz in Gordon House, Barrow Street, Dublin 4,
                    Irland, welche die Dienste im Europ&auml;ischen Wirtschaftsraum und der Schweiz betreibt.</p>
                <p>Ihre rechtliche Vereinbarung mit &laquo;YouTube&raquo; besteht aus den unter folgendem Link zu
                    entnehmenden Bestimmungen und Bedingungen: <a
                        href='https://www.youtube.com/static?gl=DE&template=terms&hl=de' target='_blank'
                        rel='noopener'>https://www.youtube.com/static?gl=de&template=terms&hl=de</a>. Diese Bestimmungen
                    bilden eine rechtlich bindende Vereinbarung zwischen Ihnen und &laquo;YouTube&raquo; bez&uuml;glich
                    der Nutzung der Dienste. In der Datenschutzerkl&auml;rung von Google wird erl&auml;utert, wie
                    &laquo;YouTube&raquo; mit Ihren personenbezogenen Daten verf&auml;hrt und Ihre Daten sch&uuml;tzt,
                    wenn Sie den Dienst nutzen.</p>
                <p>&nbsp;</p>
                <h2>Bestellabwicklung im Online-Shop mit Kundenkonto</h2>
                <p>Wir verarbeiten die Daten unserer Kunden gem. den datenschutzrechtlichen Bestimmungen des Bundes
                    (Datenschutzgesetz, DSG) und der EU-DSGVO, im Rahmen der Bestellvorg&auml;nge in unserem Onlineshop,
                    um ihnen die Auswahl und die Bestellung der gew&auml;hlten Produkte und Leistungen, sowie deren
                    Bezahlung und Zustellung, bzw. Ausf&uuml;hrung zu erm&ouml;glichen.</p>
                <p>Zu den verarbeiteten Daten geh&ouml;ren Stammdaten (Bestandsdaten), Kommunikationsdaten,
                    Vertragsdaten, Zahlungsdaten und zu den von der Verarbeitung betroffenen Personen geh&ouml;ren
                    unsere Kunden, Interessenten und sonstige Gesch&auml;ftspartner. Die Verarbeitung erfolgt zum Zweck
                    der Erbringung von Vertragsleistungen im Rahmen des Betriebs eines Onlineshops, Abrechnung,
                    Auslieferung und der Kundenservices. Hierbei setzen wir Session Cookies, z.B. f&uuml;r die
                    Speicherung des Warenkorb-Inhalts und permanente Cookies, z.B. f&uuml;r die Speicherung des
                    Login-Status ein.</p>
                <p>Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b (Durchf&uuml;hrung
                    Bestellvorg&auml;nge) und c (Gesetzlich erforderliche Archivierung) DSGVO. Dabei sind die als
                    erforderlich gekennzeichneten Angaben zur Begr&uuml;ndung und Erf&uuml;llung des Vertrages
                    erforderlich. Die Daten offenbaren wir gegen&uuml;ber Dritten nur im Rahmen der Auslieferung,
                    Zahlung oder im Rahmen der gesetzlichen Erlaubnisse und Pflichten. Die Daten werden in
                    Drittl&auml;ndern nur dann verarbeitet, wenn dies zur Vertragserf&uuml;llung erforderlich ist (z.B.
                    auf Kundenwunsch bei Auslieferung oder Zahlung).</p>
                <p>Nutzer k&ouml;nnen optional ein Nutzerkonto anlegen, indem sie insbesondere ihre Bestellungen
                    einsehen k&ouml;nnen. Im Rahmen der Registrierung, werden die erforderlichen Pflichtangaben den
                    Nutzern mitgeteilt. Die Nutzerkonten sind nicht &ouml;ffentlich und k&ouml;nnen von Suchmaschinen,
                    z.B. Google, nicht indexiert werden. Wenn Nutzer ihr Nutzerkonto gek&uuml;ndigt haben, werden deren
                    Daten im Hinblick auf das Nutzerkonto gel&ouml;scht, vorbehaltlich deren Aufbewahrung ist aus
                    handels- oder steuerrechtlichen Gr&uuml;nden entspr. Art. 6 Abs. 1 lit. c DSGVO notwendig. Angaben
                    im Kundenkonto verbleiben bis zu deren L&ouml;schung mit anschliessender Archivierung im Fall einer
                    rechtlichen Verpflichtung. Es obliegt den Nutzern, ihre Daten bei erfolgter K&uuml;ndigung vor dem
                    Vertragsende zu sichern.</p>
                <p>Im Rahmen der Registrierung und erneuter Anmeldungen sowie Inanspruchnahme unserer Onlinedienste,
                    speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung
                    erfolgt auf Grundlage unserer berechtigten Interessen, als auch der Nutzer an Schutz vor Missbrauch
                    und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grunds&auml;tzlich
                    nicht, ausser sie ist zur Verfolgung unserer Anspr&uuml;che erforderlich oder es besteht hierzu eine
                    gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO.</p>
                <p>Die L&ouml;schung erfolgt nach Ablauf gesetzlicher Gew&auml;hrleistungs- und vergleichbarer
                    Pflichten, die Erforderlichkeit der Aufbewahrung der Daten wird in unregelm&auml;ssigen
                    Abst&auml;nden &uuml;berpr&uuml;ft. Im Fall der gesetzlichen Archivierungspflichten erfolgt die
                    L&ouml;schung nach deren Ablauf.</p>
                <p>&nbsp;</p>
                <h2>Urheberrechte</h2>
                <p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der
                    Website, geh&ouml;ren ausschliesslich dem Betreiber dieser Website oder den speziell genannten
                    Rechteinhabern. Für die Reproduktion von sämtlichen Dateien, ist die schriftliche Zustimmung des
                    Urheberrechtstr&auml;gers im Voraus einzuholen.</p>
                <p>Wer ohne Einwilligung des jeweiligen Rechteinhabers eine Urheberrechtsverletzung begeht, kann sich
                    strafbar und allenfalls schadenersatzpflichtig machen.</p>
                <p>&nbsp;</p>
                <h2>Allgemeiner Haftungsausschluss</h2>
                <p>Alle Angaben unseres Internetangebotes wurden sorgf&auml;ltig gepr&uuml;ft. Wir bem&uuml;hen uns,
                    unser Informationsangebot aktuell, inhaltlich richtig und vollst&auml;ndig anzubieten. Trotzdem kann
                    das Auftreten von Fehlern nicht v&ouml;llig ausgeschlossen werden, womit wir keine Garantie f&uuml;r
                    Vollst&auml;ndigkeit, Richtigkeit und Aktualit&auml;t von Informationen auch
                    journalistisch-redaktioneller Art &uuml;bernehmen k&ouml;nnen. Haftungsanspr&uuml;che aus
                    Sch&auml;den materieller oder ideeller Art, die durch die Nutzung der angebotenen Informationen
                    verursacht wurden, sind ausgeschlossen, sofern kein nachweislich vors&auml;tzliches oder grob
                    fahrl&auml;ssiges Verschulden vorliegt.</p>
                <p>Der Herausgeber kann nach eigenem Ermessen und ohne Ank&uuml;ndigung Texte ver&auml;ndern oder
                    l&ouml;schen und ist nicht verpflichtet, Inhalte dieser Website zu aktualisieren. Die Benutzung bzw.
                    der Zugang zu dieser Website geschieht auf eigene Gefahr des Besuchers. Der Herausgeber, seine
                    Auftraggeber oder Partner sind nicht verantwortlich f&uuml;r Sch&auml;den, wie direkte, indirekte,
                    zuf&auml;llige, vorab konkret zu bestimmende oder Folgesch&auml;den, die angeblich durch den Besuch
                    dieser Website entstanden sind und &uuml;bernehmen hierf&uuml;r folglich keine Haftung.</p>
                <p>Der Herausgeber &uuml;bernimmt ebenfalls keine Verantwortung und Haftung f&uuml;r die Inhalte und die
                    Verf&uuml;gbarkeit von Website Dritter, die &uuml;ber externe Links dieser Website erreichbar sind.
                    F&uuml;r den Inhalt der verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich. Der
                    Herausgeber distanziert sich damit ausdr&uuml;cklich von allen Inhalten Dritter, die
                    m&ouml;glicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten
                    verstossen.</p>
                <p>&nbsp;</p>
                <h2>&Auml;nderungen</h2>
                <p>Wir k&ouml;nnen diese Datenschutzerkl&auml;rung jederzeit ohne Vorank&uuml;ndigung anpassen. Es gilt
                    die jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerkl&auml;rung
                    Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die
                    &Auml;nderung per E-Mail oder auf andere geeignete Weise informieren.</p>
                <p>&nbsp;</p>
                <h2>Fragen an den Datenschutzbeauftragten</h2>
                <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich
                    direkt an die f&uuml;r den Datenschutz zu Beginn der Datenschutzerkl&auml;rung aufgef&uuml;hrten,
                    verantwortlichen Person in unserer Organisation.</p>
                <p>&nbsp;</p>
                <p>Egnach, 01.07.2024
                    <!--ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall unter Kostenfolge geahndet wird.--><br />Quelle:
                    <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">SwissAnwalt</a></p>
                <!--Bitte beachten Sie die AGB von SwissAnwalt betreffend allfällig anfallenden Kosten bei Weglassen der Quelle!-->
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        metaInfo: {
            title: 'Datenschutzerklärung'
        },
    }
</script>